// src/components/Dashboard.js
import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';

const plans = [
  { id: 1, name: 'Basic Plan', price: '$10/month' },
  { id: 2, name: 'Pro Plan', price: '$20/month' },
  { id: 3, name: 'Enterprise Plan', price: '$30/month' },
];

const Dashboard = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Available Plans
      </Typography>
      <Grid container spacing={2}>
        {plans.map(plan => (
          <Grid item xs={12} sm={6} md={4} key={plan.id}>
            <Card>
              <CardContent>
                <Typography variant="h5">{plan.name}</Typography>
                <Typography variant="h6">{plan.price}</Typography>
                <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                  Select Plan
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
