// src/components/Login.js
import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Box } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { signInWithGoogle } from '../firebaseConfig'; // Ensure this path is correct
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = () => {
    // Add logic for username/password login (e.g., API call)
    alert(`Username: ${username}\nPassword: ${password}`);
    // Redirect to Dashboard
    navigate('/dashboard');
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle();
      alert('Google Login Successful!');
      // Redirect to Dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      alert('Google Login Failed');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogin}
          >
            Login
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<GoogleIcon />}
            onClick={handleGoogleLogin}
          >
            Login with Google
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
