// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCfzGUkRWf5dL_wTVp19G_zmvud5Y8Hm3M",
    authDomain: "the-saas-up.firebaseapp.com",
    projectId: "the-saas-up",
    storageBucket: "the-saas-up.appspot.com",
    messagingSenderId: "699560046633",
    appId: "1:699560046633:web:8af70c104d845d2f8c2c38",
    measurementId: "G-BCDET0CDQ9"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => signInWithPopup(auth, provider);
